/* eslint-disable camelcase */
export enum CirculationState {
  approaching = 'announced_on_platform',
  onPlatform = 'on_platform',
  leaving = 'leaving',
}

export enum TrackNumber {
  track42 = '42',
  track44 = '44',
}

export enum CirculationType {
  B = 'B',
  D = 'D',
  other = 'other',
  none = 'none',
}

export enum PriorityReason {
  PRIO_B = "Le train est prioritaire car il est attendu à quai avant le train sur l'autre voie.",
  PRIO_D = "Le train est prioritaire car il est attendu à quai avant le train sur l'autre voie.",
  'FIFO+B' = 'Le RER B est proche du B précédent.',
  'FIFO+D' = 'Le train suit la séquence normale.',
  W ='Train W.',
  BOUCHON = "Le train suit la séquence de priorisation lors d'un bouchon.",
  TROU_DESSERTE = 'Le train est prioritaire car il fait suite à un trou de desserte.',
}
export type PriorityCirculation = {
  number: string;
  track: TrackNumber;
  train_type: CirculationType;
  priority_reason: keyof typeof PriorityReason;
}

export type CirculationProps = {
  number: string;
  train_type: CirculationType;
  state: CirculationState | '';
}

export enum EventType {
  priority = 'priority',
  circulations = 'circulations',
  stream_state = 'stream_state'
}

export type PriorityEvent = {
  type: EventType.priority;
  circulations: PriorityCirculation[];
}

export type CirculationEvent = {
  type: EventType.circulations;
  track: TrackNumber | null;
  circulations: CirculationProps[];
}

export type FluxStateEvent = {
  type: EventType.stream_state;
  state: boolean;
}

export type ServerSync = {
  delta: number;
  server_time: number;
}
