/* eslint-disable react/destructuring-assignment */
import { ReactElement } from 'react'
import { auth } from '@osrdata/app_core'

import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined'

import { store } from 'Store'

type MenuItemType = {
  label: string;
  icon?: ReactElement;
  onClick: () => void;
  show: boolean;
}

const ACCOUNT_MENU_ITEMS: MenuItemType[] = [
  {
    label: 'TopBar.disconnect',
    icon: <PowerSettingsNewOutlinedIcon />,
    onClick: () => auth.logout()(store.dispatch),
    show: true,
  },
]

export default ACCOUNT_MENU_ITEMS
